header {

	.navbar-toggler-icon {
		background-image:url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.5)' stroke-width='2.7' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
	}

	.nav-link {
		text-transform: uppercase;
		font-weight: 700;
		color:theme-color("secondary");

		&.active {
			border-bottom: 2px solid #eee;
		}
	}
}