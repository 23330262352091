#start {
	background: $gray-100;
	
	.container {
		padding-top:56px;
		min-height:85vh;
		background: url("../img/hero_large.png") no-repeat center right / contain;

		h1 {
			margin-top:8vh;
		}
	}
}