$font-family-serif: "ff-tisa-web-pro",serif;
$font-family-sans: "effra",sans-serif;


$body-color: #112233;
$theme-colors: (
 "primary": #51dcad,
 "secondary": #112233,
 "pink": #ff0857,
 "purple": #3d00ff,
 "turquoise": #09778e,
 "orange": #fd6251,
 "blue": #0085c6
);


$grey-bright: #F2F2F2;
$grey-mid: #E7E7E7;
$grey-dark: #999999;
$greyish: #A3B4C5;
$black: #000000;
$white: #FFFFFF;
$darkblue: #112233;

$button-color-1: #00ffbf;
$button-color-2: #00d4e2;
$button-color-3: #f21c5e;

