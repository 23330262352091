
.fond__darkblue {
  background-color: $darkblue;
}

.fond__white {
  background-color: $white;
}

.fond__grey {
  background-color: $grey-bright;
}

.fond__grey-mid {
  background-color: $grey-mid;
}

.fond__white {
  background-color: $white;
}


.text__black {
  color: $black;
}

.text__greyish {
  color: $greyish;
  a:hover {
      color: $black;
  }
}


.text__white {
  color: $white;
  a:hover {
      color: $black;
  }
}


a:hover {
 text-decoration: none;
}

a {
  text-decoration: none;
}


/*==================================
=            Typography            =
==================================*/

$body-font-size: 16px;

body {
  font-size: $body-font-size;
  line-height: 1.6em;
  font-family: $font-family-serif;
  font-weight: normal;
  font-style: normal;
}

div, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: $font-family-sans;
  font-weight: normal;
  font-style: normal;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin: 24px 0;
  text-transform: none;
  font-size: 40px
}

h2, .h2 {
  font-size: 28px;
}

h3, .h3 {
  font-size: 21px;
  font-weight: regular;
}

h4, .h4 {
  text-transform: uppercase;
  margin: 28px 0;
  line-height: 28px;
}

.jumbotron {

  margin-bottom: 0;

  &.background-white {
    background-color: white;
  }

  p {
    font-family: $font-family-serif;
    font-size: $body-font-size;
    margin: 28px 0;
    line-height: 1.6em;
  }

  a{
    font-size: $body-font-size;
  }
}


.hero-image {
   background-image: url("./img/hero_large.png");

}
