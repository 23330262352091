// VARIABLES, INCLUDES and MIXINS

// Custom Pre
@import "variables";
@import "fonts";

/// Core
// Required
@import "node_modules/bootstrap/scss/bootstrap";
//@import "node_modules/bootstrap/scss/functions";
//@import "node_modules/bootstrap/scss/variables";
//@import "node_modules/bootstrap/scss/mixins";

// Optional
//@import "node_modules/bootstrap/scss/reboot";
//@import "node_modules/bootstrap/scss/type";
//@import "node_modules/bootstrap/scss/images";
//@import "node_modules/bootstrap/scss/grid";
//@import "node_modules/bootstrap/scss/nav";
//@import "node_modules/bootstrap/scss/navbar";
//@import "node_modules/bootstrap/scss/buttons";
//@import "node_modules/bootstrap/scss/utilities";
//@import "node_modules/bootstrap/scss/transitions";

// Custom
@import "all";

// NAVIGATION
@import "navigation";

// START 
@import "start";

// GAME 
@import "game";

// ABOUT 
@import "about";
