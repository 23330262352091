#game {
	min-height:85vh;
	
	&.screen-overview {
		padding:30px 0;

		.col-md-4 {
			margin-top:20px;
		}

		.card-img-top {
			background:#f8f9fa;
		}

		a:link, a:visited {
			color:#999;
			opacity: 1;
		}

		a:hover {
			opacity: .8;
		}

		.card-body {
			padding-top: 1rem;
		}

		h4 {
			text-transform: inherit;
			margin:5px 0;
		}
	}

	.game-ingame {
		.game-description {
			font-family: $font-family-serif;
			min-height: 100vh;
			padding-top:60px;
			text-align: center;
			background:#E8EAEF;


			.game-mainvisual {
				margin:40px;
				width:45%;
				max-width: 300px;
			}

			.game-step {
				font-weight: 700;
				font-size:2rem;
			}

			.game-step-description {
				display: inline-block;
				width: 60%;
				font-weight: 500;
				max-width: 350px;
			}
		}

		.game-content {
			min-height: 100vh;
			background: #f8f9fa;
			overflow: hidden;

			.game-breadcrumb {
				position: absolute;
				top:80px;
				right:-48px;
				margin:0;
				padding:0;
				transform: translate(-50%,0);
				z-index: 100;

				&::after {
					display:block;
					position: absolute;
					content: " ";
					top:15px;
					width: 100%;
					height: 2px;
					background:theme-color("secondary");
					z-index:-1;
				}

				li {
					position: relative;
					display: inline-block;
					width: 25px;
					height: 25px;
					margin:3px;
					text-align: center;
					line-height: 1.4;
					font-size: 0.9rem;
					background:#f8f9fa;
					border:2px solid theme-color("secondary");
					border-radius:50%;
					transition:all .3s ease-out;

					&:first-child {
						margin-left:0;
					}

					&:last-child {
						margin-right:0;
					}

					&.result {
						width:12px;
						height: 12px;
						margin-right: 0 !important;
						transform: translateY(5px);
					}

					&.active {
						margin-right:25px;
						font-weight: 700;
					}

					&.active, &.visited {
						color:$white;
						background: theme-color("secondary");
						cursor: pointer;

						&:hover {
							background:theme-color-level("secondary", -3);
							border-color:theme-color-level("secondary", -3); 
							transition:all .1s ease-out;
						}
					}
				}
			}

			.game-content-container {
				position: absolute;
				padding:60px 100px 30px 6%;
				width:100%;
				left:0;

				&.hidden {
					left:-100%;
					opacity: 0;
				}

				&.animation-in-from-left {
					animation-name: slideInFromLeft;
					animation-duration: .3s;
					animation-fill-mode: forwards;
					animation-timing-function: ease-out;
				}

				&.animation-out-to-right {
					animation-name: slideInFromRight;
					animation-duration: .3s;
					animation-fill-mode: forwards;
					animation-timing-function: ease-out;
					animation-direction: reverse;
				}

				&.animation-in-from-right {
					animation-name: slideInFromRight;
					animation-duration: .3s;
					animation-fill-mode: forwards;
					animation-timing-function: ease-out;
				}

				&.animation-out-to-left {
					animation-name: slideInFromLeft;
					animation-duration: .3s;
					animation-fill-mode: forwards;
					animation-timing-function: ease-out;
					animation-direction: reverse;
				}

				.game-question {
					font-size: 28px;
					font-weight: 500;
					margin: 4em 0 2em 0;
				}

				.game-decision {
					& > div {
						display:inline-block;
						width:300px;
						height: 200px;
						margin-right:20px;
						text-align: center;
						background:#DDE2EA;
						cursor: pointer;
						opacity: 1;

						&:hover {
							opacity:.8;
						}
					}

					.game-answer-visual {
						display: block;
						margin:0 auto;
						max-height: 250px;					
					}

					.game-answer {
						font-size: 20px;
						font-weight: 500;
						margin-top: 3em;
					}

					.answer_a {
						float: left;
						width: 45%
					}

					.answer_b {
						width: 45%
					}

				}

				.game-navigation {
					position: absolute;
					bottom: 30px;
					right:10%;
				}
			}


			.game-content-result {
				margin-top:60px;
				padding:30px;
				max-height: calc(100vh - 60px);
				overflow: auto;

				h3 {
					display: inline-block;
					font-weight: 500;
					margin-right: 130px;
				}

				.game-content-biases {
					position: relative;
					margin:0;
					padding:0;
					list-style: none;


					li {
						margin:10px 150px 20px 70px;
						font-size:17px;
					}

					li::before {
						display:block;
						position: absolute;
						width:43px;
						height: 43px;
						margin-left: -70px;
					}
				}

				.game-content-biases.positive li::before {
					content: url("../img/result_positive.svg");
				}

				.game-content-biases.negative {
					margin-top:80px;

					&::before {
						content: " ";
						display: block;
						position: absolute;
						width:35px;
						height: 6px;
						margin:-40px 70px;
						background: theme-color("secondary");
					}

					li::before {
						content: url("../img/result_negative.svg");
					}
				}
			}
		}

		&.game-loading {
			.game-content .game-content-loading {
				position: absolute;
				top:50%;
				left:50%;
				transform: translate(-50%,-50%);
				font-weight: 500;

				text-align: center;
				color:#808080;

				img {
					display: block;
					margin: 0 auto; 
				}
			}

			.game-description {
				.game-mainvisual {
					display: inline-block;
					height:300px;
					width:300px;
					border-radius: 50%;
					background:#D9DCED;
					animation: movingGradientBg 2.3s .8s linear infinite;
			    	background:linear-gradient( -45deg, #D9DCED 40%, #e2e4f1 50%, #D9DCED 60% );
			    	background-size:400% 400%;

				}
				.game-step {
					display: block;
					width:200px;
					height:35px;
					margin-left:auto;
					margin-right:auto;
					background: #D9DCED;
					animation: movingGradientBg 2.3s .8s linear infinite;
			    	background:linear-gradient( -45deg, #D9DCED 40%, #e2e4f1 50%, #D9DCED 60% );
			    	background-size:400% 400%;
				}
				.game-step-description {
					display: inline;
					color:transparent;
					background:#D9DCED;
					animation: movingGradientBg 2.3s .8s linear infinite;
			    	background:linear-gradient( -45deg, #D9DCED 40%, #e2e4f1 50%, #D9DCED 60% );
			    	background-size:400% 400%;
				}

			}
		}
	}
}


@keyframes slideInFromLeft {
    from {opacity:0;left:-100%;}
    to {opacity:1;left:0;}
}

@keyframes slideInFromRight {
    from {opacity:0;left:100%;}
    to {opacity:1;left:0;}
}

@keyframes movingGradientBg {
    from {
    	background-position: 0, 0;
    }
    to {
   		background-position: -300%, -300%;
    }
}